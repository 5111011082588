<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";

import { useWindowSize, useCssVar } from "@vueuse/core";
import router from "@/router";
import store from "@/store";
import { mapState } from "vuex";
import { mapActions } from "vuex";
import moment from "moment";

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");

// const Tree = () => import('@/views/')

export default {
  data() {
    return {
      timer: null,
    };
  },
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    // Tree
  },
  // mounted: function () {
  //   this.timer = setInterval(() => {
  //   this.dispatchcall()
  // }, 30000);
  //   window.setInterval(() => {
  //     console.log("check cookies");
  //     if (
  //       $cookies.get("token") &&
  //       $cookies.get("token") == "null" &&
  //       this.$store.state.auth.token
  //     ) {
  //       this.$store.dispatch("logout");
  //       this.$router.push("/login");
  //     }
  //     if (
  //       $cookies.get("token") &&
  //       $cookies.get("token") != "null" &&
  //       $cookies.get("token") != this.$store.state.auth.token
  //     ) {
  //       // console.log("cookies chnaged");
  //       this.$store.commit("setToken", $cookies.get("token"));
  //       this.$store.commit("setTenantid", $cookies.get("cid"));
  //       this.$store.dispatch("getCurrentUserandReloadBasedonCookie").then((response) => {
  //         this.$router.push("/?refresh=true");
          
  //       });
      
  //     }
  //   }, 1000);
  // },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change4
  
  created: function () {
    var self = this;
    //Adding authorization token
    this.$http.interceptors.request.use(
      (config) => {
        // See how much time is left to expire the current token.
       
        const token = this.$store.state.auth.token;
        // alert(token);
        // console.log(this.$store.state.auth.token + "from interceptor")
        // const tenantid = this.$store.getters.tenantid;
        const requesttime = moment().format();
        console.log(token + "token1")
        console.log('request send at'+requesttime)
        this.$store.commit('setLastRequestTime',requesttime)
        if (this.$store.state.auth.token) {
          config.headers.Authorization = `Bearer ${token}`;
          // config.headers["cid"] = tenantid;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
    //If API response is 401, redirect to login page
    this.$http.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        return new Promise(function (resolve, reject) {
          if (error.response.status === 401) {
            setTimeout(() => {
              store.dispatch("auth/logout");
              router.push("/login");
            }, 10);
          }
          if (error.response.status === 403) {
            router.push("/access-denied");
          }
          if (error.response.status === 500) {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Server Error",
                autoHideDelay: 5000,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          }

          throw error.response;
        });
      }
    );
  },
  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
  },
 
  watch: {
    standardDetails(a, b) {
      this.$route.meta.pageTitle = a.name;
    },
  },
  methods: {
    ...mapActions(["checkTokenExpiry"]),
    dispatchcall() {
      store.dispatch("checkTokenExpiry");
      console.log("am from dispatch call");
    },
  },
 
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses,
    };
  },
  //   beforeDestroy() {
  //   clearInterval(this.timer)
  // }
};
</script>
