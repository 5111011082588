import axiosIns from "@/libs/axios";
import axios from "axios";
import router from "../../router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "..";
import moment from "moment";
import { integer } from "vee-validate/dist/rules";

const tokenExpiryTime = 840;
//in seconds

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const state = {
  token: null,
  tenantid: null,
  role: null,
  workspaces: null,
  orgname: null,
  permissions: null,
  tokenExpiry: null,
  lastrequesttime: null,
  user_id: null,
  user: null,
  twofastatus: null,
  enabled_modules: [],
};

const getters = {
  isLoggedIn: (state) => !!state.token,
  isAdmin: (state) => state.role == "organization-owner",
  token: (state) => state.token,
  tenantid: (state) => state.tenantid,
  role: (state) => state.role,
  workspaces: (state) => state.workspaces,
  orgname: (state) => state.name,
  permissions: (state) => state.permissions,
  tokenExpiry: (state) => state.tokenExpiry,
  // name:state => state.name
};

const actions = {
  login({ commit, dispatch }, user) {
    return new Promise((resolve, reject) => {
      const BASE_URL = process.env.VUE_APP_ONECSF_API_URL;
      const bodyFormData = new FormData();
      if (user.email) {
        bodyFormData.append("email", user.email);
        bodyFormData.append("password", user.password);
      } else {
        bodyFormData.append("first_auth_token", user.first_auth_token);
        bodyFormData.append("one_time_password", user.one_time_password);
      }

      axios({
        url: `${BASE_URL}/auth/login`,
        data: bodyFormData,
        method: "POST",
      })
        .then((resp) => {
          let first_auth_token = resp.data.data.data
            ? resp.data.data.data.first_auth_token
            : "";
          let is_2fa_enabled = resp.data.data.data
            ? resp.data.data.data.is_2fa_enabled
            : "";
          if (first_auth_token && is_2fa_enabled) {
            // const url = "/2FA";
            // this.$router.replace(url);
            router.push({
              name: "TwoFA",
              params: { id: resp.data.data.data.first_auth_token },
            });
          } else {
            const accesstoken = resp.data.data.token;

            const workspaces = resp.data.data.workspaces;
            const user = resp.data.data.user;
            const roles = resp.data.data.roles;
            const permissions = resp.data.data.permissions;
            const user_id = resp.data.data.user_id;
            commit("setToken", accesstoken);

            commit("setWorkspaces", workspaces);
            if (
              resp.data.data.workspaces &&
              typeof resp.data.data.workspaces != "undefined" &&
              resp.data.data.workspaces.length > 0
            ) {
              const tenantid = resp.data.data.workspaces[0].tenant_uid;
              commit("setTenantid", tenantid);
            }
            // commit("setTenantid",workspaces[0].tenant_uid);
            commit("setUser", user);
            commit("setUserid", user_id);
            commit("setRole", roles);
            commit("setPermissions", permissions);
            commit("set2Fastatus", user.is_2fa_enabled);
            commit("setEnabled_modules", resp.data.data.enabled_modules);
            var expires_at = moment().add(tokenExpiryTime, "seconds");
            dispatch("setExpiry", expires_at);

            resolve(resp);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  setExpiry({ commit }, exp) {
    commit("setTokenExpiry", exp);
  },
  checkTokenExpiry({ dispatch, state }) {
    console.log("current time " + moment().format());
    console.log("token expiry " + moment(state.tokenExpiry).format());
    console.log(
      "last request " +
        moment(state.lastrequesttime)
          .add(parseInt(tokenExpiryTime - 30), "seconds")
          .format()
    );

    if (
      moment().format() >= moment(state.tokenExpiry).format() &&
      moment(state.lastrequesttime)
        .add(parseInt(tokenExpiryTime - 30), "seconds")
        .format() > moment().format()
    ) {
      dispatch("refreshtoken");
      // var expires_at =  moment().add(tokenExpiryTime, "minutes");
      // dispatch("setExpiry", expires_at);
    }
    // console.log(moment().format() + "/" +moment(state.tokenExpiry).format() + 'its a time compare' )
  },
  refreshtoken({ commit, dispatch }) {
    const { token } = state;
    const BASE_URL = process.env.VUE_APP_ONECSF_API_URL;
    axiosIns({ url: `${BASE_URL}/auth/refresh-token`, method: "POST" })
      .then((resp) => {
        console.log(resp);
        const accesstoken = resp.data.data.token;
        // const workspaces = resp.data.workspaces;
        const user = resp.data.data.user;
        const user_id = resp.data.data.user_id;
        // const roles = resp.data.roles;
        // const permissions = resp.data.permissions;
        commit("setToken", accesstoken);
        // commit("setWorkspaces", workspaces);
        commit("setUser", user);
        commit("setUserid", user_id);
        // commit("setRole", roles);
        // commit("setPermissions", permissions);
        var expires_at = moment().add(tokenExpiryTime, "seconds");
        dispatch("setExpiry", expires_at);
      })
      .catch((err) => {});
  },
  getCurrentUserandReloadBasedonCookie({ commit, dispatch }) {
    console.log("abcd");
    // console.log(this.$store.state.auth.token + "from reload function")
    return new Promise((resolve, reject) => {
      const options = {
        method: "GET",
        headers: { Authorization: `Bearer  ${$cookies.get("token")}` },
        url: process.env.VUE_APP_ONECSF_API_URL + `/current-user`,
      };
      var self = this;
      axios(options)
        .then((resp) => {
          // const accesstoken = resp.data.data.token;
          // const tenantid = resp.data.user.tenant_id[0]
          const workspaces = resp.data.data.workspaces;
          const user = resp.data.data.user;
          const roles = resp.data.data.roles;
          const permissions = resp.data.data.permissions;
          // commit("setToken", accesstoken);
          commit("setWorkspaces", workspaces);
          commit("setUser", user);
          commit("setRole", roles);
          commit("setPermissions", permissions);
          var expires_at = moment().add(tokenExpiryTime, "seconds");
          dispatch("setExpiry", expires_at);

          resolve(resp);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  logout: ({ commit }) => {
    commit("setToken", null);
    commit("setTenantid", null);
    commit("setUser", null);
    commit("setWorkspaces", null);
    commit("setPermissions", null);
    commit("set2Fastatus", null);
    state.user = {
      fullname: null,
    };
    router.push("/login");
    // console.log('logout clicked')
  },

  changetenant({ commit, dispatch }, payload) {
    //  console.log(payload)
    return new Promise((resolve) => {
      // setTimeout(() => {
      commit("setTenantid", payload);
      dispatch("getFavPeople");
      dispatch("getFavDept");
      dispatch("getFavTeam");
      dispatch("getMasters");
      // dispatch('getMastersandReload')
      // dispatch('getMastersandReload')
      // window.location.reload();
      // this.$router.push('/')
      resolve();
      // }, 1000)
    });

    //  window.location.reload();
  },
};

const mutations = {
  setToken: (state, token) => {
    // $cookies.set("token",token,null, null, process.env.VUE_APP_DOMAIN);
    // console.log($cookies.get("token") + "from state cokkie token")
    console.log(token, "state token");
    state.token = token;
  },
  setTenantid: (state, tenantid) => {
    $cookies.set("cid", tenantid, null, null, process.env.VUE_APP_DOMAIN);
    state.tenantid = tenantid;
  },
  setWorkspaces: (state, workspaces) => {
    state.workspaces = workspaces;
  },
  setRole: (state, role) => {
    state.role = role;
  },
  setPermissions: (state, permissions) => {
    state.permissions = permissions;
  },
  setOrgName: (state, name) => {
    state.orgname = name;
  },
  setUser(state, user) {
    state.user = user;
    if (user != null)
      state.user.fullname = user.firstname + " " + user.lastname;
  },
  setTokenExpiry: (state, tokenExpiry) => {
    state.tokenExpiry = tokenExpiry;
  },
  setLastRequestTime: (state, lastrequesttime) => {
    state.lastrequesttime = lastrequesttime;
  },
  setUserid: (state, userid) => {
    state.user_id = userid;
  },
  set2Fastatus: (state, twofastatus) => {
    state.twofastatus = twofastatus;
  },
  setEnabled_modules: (state, enabled_modules) => {
    state.enabled_modules = enabled_modules;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
