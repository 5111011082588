import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
// import Axios from 'axios'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'
// Vue.prototype.$http = Axios
// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

//setup cookie for cross login
import VueCookies from 'vue-cookies'
Vue.prototype.$cookies = VueCookies;

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
