import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { isUserLoggedIn } from "@/auth/utils";

Vue.use(VueRouter);
function guardRoute(to, from, next) {
  let isAuthenticated = false;
  // this is just an example. You will have to find a better or
  // centralised way to handle you localstorage data handling
  console.log(isUserLoggedIn());
  if (isUserLoggedIn()) {
    isAuthenticated = true;
  } else {
    isAuthenticated = false;
  }
  if (isAuthenticated) {
    next(); // allow to enter route
  } else {
    next("/login"); // go to '/login';
  }
}
const router = new VueRouter({
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/listing.vue"),
      meta: {
        pageTitle: "Home",
        requiresAuth: true,
      },
    },
    {
      path: "/Standard/:id",
      name: "Standard",
      component: () => import("@/views/Standard.vue"),
      meta: {
        pageTitle: "Standards",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Standard",
            active: true,
          },
        ],
      },
    },
    {
      path: "/Standard/:id/control/:controlId",
      name: "ControlDetails",
      component: () => import("@/views/ControlView.vue"),
      meta: {
        pageTitle: "Controls",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Standards",
            to: { name: "home" },
          },
          {
            text: "Controls",
            active: true,
          },
        ],
      },
    },
    {
      path: "/Standard/:id/draft",
      name: "Standarddraft",
      component: () => import("@/views/draft/StandardDraft.vue"),
      meta: {
        pageTitle: "Standards",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Standard",
            active: true,
          },
        ],
      },
    },
    {
      path: "/periodicactivity",
      name: "periodicactivity",
      component: () => import("@/views/periodicactivity/PeriodicActivity.vue"),
      meta: {
        pageTitle: "Periodic Activity",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Periodic Activity",
            active: true,
          },
        ],
      },
    },
    {
      path: "/periodicdetails/:id",
      name: "periodicdetails",
      component: () => import("@/views/periodicactivity/PeriodicDetails.vue"),
      meta: {
        pageTitle: "Periodic Activity Details",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Periodic Activity Details",
            active: true,
          },
        ],
      },
    },
    {
      path: "/customers",
      name: "customers",
      component: () => import("@/views/customers/Customers.vue"),
      meta: {
        pageTitle: "Customers",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Customers",
            active: true,
          },
        ],
      },
    },
    {
      path: "/customer_members/:id",
      name: "customermember",
      component: () => import("@/views/customers/Customer_members.vue"),
      meta: {
        pageTitle: "Members",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Members",
            active: true,
          },
        ],
      },
    },
    {
      path: "/detailVulnerabilities/:id",
      name: "detailVulnerabilities",
      component: () =>
        import("@/views/vulnerability/detailVulnerabilities.vue"),
      meta: {
        pageTitle: "Vulnerability details",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Vulnerabilities",
            active: true,
          },
        ],
      },
    },
    {
      path: "/assetdetails/:id",
      name: "assetdetails",
      component: () => import("@/views/assetcategory/assetdetails.vue"),
      meta: {
        pageTitle: "Asset Category details",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Asset",
            active: true,
          },
        ],
      },
    },
    {
      path: "/addthreats",
      name: "addthreats",
      component: () => import("@/views/threats/addthreats.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Add threats",
        breadcrumb: [
          {
            text: "Add",
            active: true,
          },
        ],
      },
    },
    {
      path: "/Treelist/:id",
      name: "Treelist",
      component: () => import("@/views/Treelist.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "List View",
        breadcrumb: [
          {
            text: "Treelist",
            active: true,
          },
        ],
      },
    },
    {
      path: "/addrisk",
      name: "addrisk",
      component: () => import("@/views/risk/addrisk.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Add new risk",
        breadcrumb: [
          {
            text: "Add",
            active: true,
          },
        ],
      },
    },
    {
      path: "/addrole",
      name: "addrole",
      component: () => import("@/views/roles/addrole.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Add Role",
        breadcrumb: [
          {
            text: "Add",
            active: true,
          },
        ],
      },
    },
    {
      path: "/adduser",
      name: "adduser",
      component: () => import("@/views/usermanagment/adduser.vue"),
      meta: {
        pageTitle: "Add new user",
        requiresAuth: true,
        breadcrumb: [
          {
            text: "Add",
            active: true,
          },
        ],
      },
    },
    {
      path: "/editrole/:id",
      name: "editrole",
      component: () => import("@/views/roles/editrole.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Role details",
        breadcrumb: [
          {
            text: "Role",
            active: true,
          },
        ],
      },
    },

    {
      path: "/viewrisk/:id",
      name: "viewrisk",
      component: () => import("@/views/risk/viewrisk.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Risk details",
        breadcrumb: [
          {
            text: "Risk",
            active: true,
          },
        ],
      },
    },
    {
      path: "/theratdetail/:id",
      name: "theratdetail",
      component: () => import("@/views/threats/theratdetail.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Threat details",
        breadcrumb: [
          {
            text: "Therat",
            active: true,
          },
        ],
      },
    },
    {
      path: "/userdetail/:id",
      name: "userdetail",
      component: () => import("@/views/usermanagment/userdetail.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "User details",
        breadcrumb: [
          {
            text: "User",
            active: true,
          },
        ],
      },
    },
    {
      path: "/listing",
      name: "listing",
      component: () => import("@/views/listing.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Standard",
        breadcrumb: [
          {
            text: "listing",
            active: true,
          },
        ],
      },
    },
    {
      path: "/addassets",
      name: "addassets",
      component: () => import("@/views/assetcategory/addassets.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Add Asset Categories",
        breadcrumb: [
          {
            text: "Add",
            active: true,
          },
        ],
      },
    },
    {
      path: "/assets",
      name: "assets",
      component: () => import("@/views/assetcategory/assets.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Asset Categories",
        breadcrumb: [
          {
            text: "Assets",
            active: true,
          },
        ],
      },
    },
    {
      path: "/usermanagement",
      name: "usermanagement",
      component: () => import("@/views/usermanagment/usermanagement.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "User Management",
        breadcrumb: [
          {
            text: "User Management",
            active: true,
          },
        ],
      },
    },
    {
      path: "/roles",
      name: "roles",
      component: () => import("@/views/roles/roles.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Roles",
        breadcrumb: [
          {
            text: "Roles",
            active: true,
          },
        ],
      },
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import("@/views/profile/Profile.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Profile",
        breadcrumb: [
          {
            text: "Profile",
            active: true,
          },
        ],
      },
    },
    {
      path: "/2FA/:id",
      name: "TwoFA",
      component: () => import("@/views/TwoFA.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/setnewpassword",
      name: "setnewpassword",
      component: () => import("@/views/setnewpassword.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/resetpassword",
      name: "resetpassword",
      component: () => import("@/views/resetpassword.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/addvulnerabilities",
      name: "addvulnerabilities",
      component: () => import("@/views/vulnerability/addvulnerabilities.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Add vulnerability",
        breadcrumb: [
          {
            text: "Add",
            active: true,
          },
        ],
      },
    },
    {
      path: "/risk",
      name: "risk",
      component: () => import("@/views/risk/risk.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Risk",
        breadcrumb: [
          {
            text: "Risk",
            active: true,
          },
        ],
      },
    },
    {
      path: "/vulnerabilities",
      name: "vulnerabilities",
      component: () => import("@/views/vulnerability/vulnerabilities.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Vulnerabilities",
        breadcrumb: [
          {
            text: "Vulnerabilities",
            active: true,
          },
        ],
      },
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("@/views/dashboard/Dashboard.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Dashboard",

        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
          },
        ],
      },
    },
    {
      path: "/deleted-users",
      name: "deleted-users",
      component: () => import("@/views/DeletedUsers/Listing.vue"),
      // meta: {
      //   requiresAuth: true,
      //   pageTitle: "Deleted Users",

      //   breadcrumb: [
      //     {
      //       text: "Dashboard",
      //       active: true,
      //     },
      //   ],
      // },
    },
    {
      path: "/mytasks",
      name: "mytasks",
      component: () => import("@/views/dashboard/Mytasks.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "My Tasks",
        breadcrumb: [
          {
            text: "My Tasks",
            active: true,
          },
        ],
      },
    },
    {
      path: "/assignedtasks",
      name: "assignedtasks",
      component: () => import("@/views/dashboard/AssignedTasks.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Assigned Tasks",
        breadcrumb: [
          {
            text: "Assigned Tasks",
            active: true,
          },
        ],
      },
    },
    {
      path: "/threat",
      name: "threat",
      component: () => import("@/views/threats/threat.vue"),
      meta: {
        requiresAuth: true,
        pageTitle: "Threats",
        breadcrumb: [
          {
            text: "Threat",
            active: true,
          },
        ],
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/forgotpassword",
      name: "forgotpassword",
      component: () => import("@/views/forgotpassword.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/editstandards/:id",
      name: "editstandards",
      component: () => import("@/views/editstandards.vue"),
      meta: {
        requiresAuth: true,
        adminAuth: true,
      },
    },
    {
      path: "/tprquestions",
      name: "tprquestions",
      component: () => import("@/views/tpr/TprQuestions.vue"),
      meta: {
        requiresAuth: true,
        adminAuth: true,
        pageTitle: "TPR Questions",
        breadcrumb: [
          {
            text: "TPR Questions",
            active: true,
          },
        ],
      },
    },
    {
      path: "/tprqts/:id",
      name: "tprqts",
      component: () => import("@/views/tpr/TprQts.vue"),
      meta: {
        requiresAuth: true,
        adminAuth: true,
        pageTitle: "Questions",
        breadcrumb: [
          {
            text: "Questions",
            active: true,
          },
        ],
      },
    },
    {
      path: "/questions-uploads",
      name: "questions-uploads",
      component: () => import("@/views/tpr/QuestionsUploadListing.vue"),
      meta: {
        requiresAuth: true,
        adminAuth: true,
        pageTitle: "File Uploads",
        breadcrumb: [
          {
            text: "TPR Questions",
            to: { name: "tprquestions" },
          },
          {
            text: "File Uploads",
            active: true,
          },
        ],
      },
    },
    {
      path: "/legalregister",
      name: "legalregister",
      component: () => import("@/views/LegalRegister/LegalRegister.vue"),
      meta: {
        requiresAuth: true,
        adminAuth: true,
        pageTitle: "Legal Register",
        breadcrumb: [
          {
            text: "Legal Register",
            active: true,
          },
        ],
      },
    },

    {
      path: "/legalregister-uploads",
      name: "legalregister-uploads",
      component: () => import("@/views/LegalRegister/FileUploadsListing.vue"),
      meta: {
        requiresAuth: true,
        adminAuth: true,
        pageTitle: "File Uploads",
        breadcrumb: [
          {
            text: "Legal Register",
            to: { name: "legalregister" },
          },
          {
            text: "File Uploads",
            active: true,
          },
        ],
      },
    },

    {
      path: "/policies",
      name: "policies",
      component: () => import("@/views/policies/listing.vue"),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/access-denied",
      name: "error-404",
      component: () => import("@/views/error/AccessDenied.vue"),
      meta: {
        layout: "full",
      },
    },

    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["isLoggedIn"]) {
      next();
      return;
    }
    localStorage.setItem("from_url", to.path);
    next("/login");
  } else {
    next();
  }
});

export default router;
